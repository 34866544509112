import * as React from 'react'

import Header from '../components/header'
import Footer from '../components/footer'

const containerStyles = {
  margin: `3rem auto`,
  maxWidth: 800,
}
const listStyles = {
  display: `flex`,
  justifyContent: `center`,
}

const Lasso = () => {
	return (
		<div className="w3-container" style={containerStyles}>
			<title>Welcome to Lasso Courier</title>
      <Header />
			<main className="w3-padding w3-padding-24">
				<div>
          <p>
            We're a courier company in Atlanta blah blah blah serving food delivery and stuff blah blah blah blah.
          </p>
        </div>
				<div className="w3-row" style={listStyles}>
          <ul className="w3-ul w3-border w3-center w3-margin w3-half">
            <li>
              <h3>Our Restaurants</h3>
            </li>
            <li>
              <a className="w3-button w3-block" href="#">Grant Park Market</a>
            </li>
            <li>
              <a className="w3-button w3-block" href="#">Ticonderoga Club</a>
            </li>
            <li>
              <a className="w3-button w3-block" href="#">Lottafrutta</a>
            </li>
            <li>
              <a className="w3-button w3-block" href="#">Folk Art</a>
            </li>
          </ul>
        </div>
			</main>
      <Footer />
    </div>
	)
}

export default Lasso
