import * as React from 'react'
import { Link } from 'gatsby'

import "./header.css"

const headerStyles = {
  display: `flex`,
  alignItems: `center`,
}

const Header = () => {
  const handleClick = (e) => {
    let dropdown = document.getElementById('dropdown-content')
    if(dropdown.classList.contains('w3-show')) {
      dropdown.classList.remove('w3-show')
    }
    else {
      dropdown.classList.add('w3-show')
    }
  }

  return (
    <header className="w3-row w3-padding" style={headerStyles}>
      <div className="w3-half">
        <h1>Lasso Courier</h1>
      </div>
      <div className="w3-half w3-bar" id="large-nav">
        <Link to="/about" className="w3-bar-item w3-button">About Lasso</Link>
        <a href="#" className="w3-bar-item w3-button">Shop</a>
      </div>
      <div className="w3-half w3-dropdown-click" id="mobile-nav">
        <button className="w3-button" onClick={handleClick}>
          <i className="fas fa-bars w3-large"></i> Menu
        </button>
        <div className="w3-dropdown-content w3-bar-block w3-border" id="dropdown-content">
          <Link to="/about" className="w3-bar-item w3-button">About Lasso</Link>
          <a href="#" className="w3-bar-item w3-button">Shop</a>
        </div>
      </div>
    </header>
  )
}

export default Header