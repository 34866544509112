import * as React from 'react'

import './footer.css'

const footerStyles = {
  alignItems: `center`,
}

const Footer = () => {
  return (
    <footer className="w3-row" style={footerStyles}>
      <div className="w3-container w3-third m4 s12 w3-center">
        <p>
          Contact Info
        </p>
      </div>
      <div className="w3-container w3-third m4 s12 w3-center">
        <p>
          Established 2021.
        </p>
      </div>
      <div className="w3-container w3-third m4 s12 w3-center w3-padding-16">
        <i className="fab fa-instagram w3-xlarge"></i>
      </div>
    </footer>
  )
}

export default Footer